function getRoutePrefixFromPublicationType(publicationType) {
  switch (publicationType) {
    case 'pedagogicalResource':
      return 'ressource';
//    case 'methodologicalResource':  // waiting
//      return 'ressource';
    case 'event':
      return 'evenement';
    case 'news':
      return 'info';
    case 'article':
      return 'decouvrir-ifprofs';
    case 'discussion':
      return 'discussion';
    case 'topic':
      return 'boite-outils/sujet';
    default:
      return '';
  }
}

module.exports = getRoutePrefixFromPublicationType;
