import React, { useState } from 'react';
import { List, ListItem } from '@chakra-ui/react';
import HeaderSubNav from '@components/presentational/HeaderSubNav';
import HeaderMainNavItem from '@components/HeaderMainNavItem';
import { LinkProps } from 'next/dist/client/link';
import { UrlObject } from 'url';

type HeaderMainMenuItemProps = LinkProps & {
  children?: React.ReactNode;
  label: string;
  isActive: boolean;
  baseColor: string;
  href: string | UrlObject;
};

export default function HeaderMainMenuItem({
  children = null,
  label,
  isActive = false,
  baseColor,
  href,
  ...props
}: HeaderMainMenuItemProps) {
  // Etat local pour gérer l'affichage du sous-menu
  const [isSubNavVisible, setIsSubNavVisible] = useState(isActive);

  // Fonction pour activer ou désactiver le sous-menu
  const toggleSubNavVisibility = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };

  return (
    <ListItem position={['relative', null, 'static']} zIndex="docked">
      <HeaderMainNavItem
        baseColor={baseColor}
        label={label}
        isActive={isSubNavVisible}
        isCaretVisible={label !== 'Ressources' ? children : false}
        onClickToggleSubNav={toggleSubNavVisibility}
        href={href}
        {...props}
      />
      {children && (
        <HeaderSubNav bg={baseColor + '.25'} isVisible={isSubNavVisible}>
          <List
            py={{ base: 4, md: 0 }}
            display={{ md: 'flex' }}
            alignItems={{ md: 'center' }}
            gap={{ md: '2rem' }}
            width="100%"
          >
            {children}
          </List>
        </HeaderSubNav>
      )}
    </ListItem>
  );
}
