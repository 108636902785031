import React from 'react';
import publicationLink from './publicationLink';
import getConfig from 'next/config';

const host = getConfig().publicRuntimeConfig.HOST;

export default function PublicationCanonicalUrl(props) {
  return (
    <link
      rel="canonical"
      href={`${host}${publicationLink(props.publication)}`}
    />
  );
}
