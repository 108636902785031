import { UserWithRefreshedAt } from '@/types/core/User';
import { ReduxRootState } from '@/types/store/ReduxRootState';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useUser(): UserWithRefreshedAt | null {
  return useSelector((state: ReduxRootState) => state.authentication.user);
}

export function useIsWorldOrganiser(): boolean {
  const currentUser = useUser();
  return useMemo(() => {
    return currentUser && currentUser.roles.includes('ROLE_WORLD_ORGANISER');
  }, [currentUser]);
}

export function useIsCountryOrganiser(): boolean {
  const currentUser = useUser();
  const isWorldOrganiser = useIsWorldOrganiser();
  return useMemo(() => {
    if (!currentUser) {
      return false;
    }
    return (
      currentUser.roles.includes('ROLE_COUNTRY_ORGANISER') || isWorldOrganiser
    );
  }, [currentUser]);
}
