import { Box, Flex, SkipNavLink, VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import UIWidth from '@components/presentational/UIWidth';
import HeaderLogos from '@components/HeaderLogos';

export default function HeaderBase({
  children,
  mainNavItemActive,
}: {
  children: ReactNode;
  mainNavItemActive: string;
}) {
  const rubs = ['ressources', 'formations', 'communautes'];
  return (
    <Box
      height={{
        md: rubs.includes(mainNavItemActive) ? '12.625rem' : '7.625rem',
      }}
      maxHeight={{
        md: rubs.includes(mainNavItemActive) ? '12.625rem' : '7.625rem',
      }}
      borderBottom={{
        md: rubs.includes(mainNavItemActive) ? 'solid 1px' : null,
      }}
      borderBottomColor={{
        md: rubs.includes(mainNavItemActive) ? 'brand.100' : null,
      }}
      backgroundColor={mainNavItemActive == 'ressources' ? 'tuile.10' : null}
    >
      <Box
        borderBottom="solid 1px"
        borderBottomColor="brand.100"
        backgroundColor="white"
      >
        <UIWidth>
          <SkipNavLink
            mx="auto"
            _focusVisible={{
              top: '.5rem',
              left: '.5rem',
              padding: '.25rem .5rem',
              position: 'relative',
              outline: 'revert',
              width: ['calc(100% - 1rem)', '20ch'],
              textAlign: 'center',
              borderRadius: 'full',
            }}
          >
            Aller au contenu
          </SkipNavLink>
        </UIWidth>
        <UIWidth basePx="0">
          <Flex
            justifyContent="space-between"
            alignItems={{ base: 'center', md: 'flex-start' }}
            position="relative"
            height={{ md: '7.625rem' }}
          >
            <HeaderLogos />
            <VStack
              display={{ base: 'block', md: 'flex' }}
              align="flex-end"
              gap={{ base: 0, md: '.4375rem' }}
              mt={{ md: '.75rem' }}
              flexGrow={{ md: 1 }}
              ml={{ md: '3rem', lg: '6rem' }}
              justify={{ md: 'flex-start' }}
            >
              {children}
            </VStack>
          </Flex>
        </UIWidth>
      </Box>
    </Box>
  );
}
