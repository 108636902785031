import {
  Box,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

export default function Modal({
  accentColor = null,
  isOpen,
  onClose,
  scrollBehavior = 'inside',
  isCentered = true,
  size = null,
  onCloseComplete = null,
  children,
  isClosable = true,
}) {
  return (
    <ChakraModal
      scrollBehavior={scrollBehavior}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={size}
      onCloseComplete={onCloseComplete}
      trapFocus={false}
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="0"
        borderBottomRightRadius={size !== 'full' && '1.875rem'}
        minWidth={size ? size : ['90%', '', 'fit-content']}
        position="relative"
      >
        <Box
          height="0.625rem"
          width="100%"
          bg={accentColor ? accentColor + '.100' : 'brand.100'}
          position="absolute"
          top="-0.625rem"
          left="0"
        />
        {isClosable && <ModalCloseButton size="md" color="brand.100" />}
        {children}
      </ModalContent>
    </ChakraModal>
  );
}
