import React from "react";
import {
  Box,
  useTheme,
  Flex,
} from "@chakra-ui/react";

export default function HeaderSubNav({bg, isVisible, children}) {
  const theme = useTheme();
  return (
    <Box
      display={{ base: (isVisible ? "block" : "none") }}
      backgroundColor={{ base: bg, md: "transparent" }}
      px={{ base: 4, md: 0 }}
      maxWidth={{ md: "100vw" }}
      position={{ md: "absolute" }}
      left={{ md: 0 }}
      top={{ md: "7.6875rem" }}
      right={{ md: 0 }}
    >
      <Flex maxWidth={theme.uiWidth} mx={0} pl={{ md: "12.75rem" }} minHeight={{ md: "4.875rem" }}>
        {children}
      </Flex>
    </Box>
  );
}