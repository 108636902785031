import { Flex, MenuDivider, MenuItem, useToast } from '@chakra-ui/react';
import { PiGearSixBold, PiSignOutBold } from 'react-icons/pi';
import React, { ReactNode, useMemo } from 'react';
import { LinkProps } from 'next/dist/client/link';
import { UrlObject } from 'url';
import NextLink from 'next/link';
import { ReduxRootState } from '@/types/store/ReduxRootState';
import { useDispatch, useSelector } from 'react-redux';
import isGranted, { isUserOrganiser } from '@/components/app/users/isGranted';
import { userDisconnectedAction } from '@/redux/modules/authentication';
import { useRouter } from 'next/router';

function NotificationItem({ children }, { children: ReactNode }) {
  return (
    <Flex
      as="span"
      display="inline-flex"
      color="white"
      background="danger.100"
      fontSize=".75rem"
      lineHeight="1rem"
      minWidth="1rem"
      height="1rem"
      borderRadius="full"
      justifyContent="center"
      alignItems="center"
      fontFamily="sans-serif"
      px=".25rem"
      ml={4}
    >
      {children}
    </Flex>
  );
}
export function HeaderMenuItems({
  notifications = 0,
  messages = 0,
}: {
  notifications: number;
  messages: number;
}) {
  const user = useSelector(
    (state: ReduxRootState) => state.authentication.user
  );
  const dispatch = useDispatch();
  const toast = useToast();
  const { push } = useRouter();

  const isWorldOrganiser = useMemo(() => {
    return isGranted(user, 'ROLE_WORLD_ORGANISER');
  }, [user]);
  const isCountryOrganiser = useMemo(() => {
    return isGranted(user, 'ROLE_COUNTRY_ORGANISER');
  }, [user]);

  const logout = async () => {
    await fetch('/api/logout', { method: 'POST' });
    dispatch(userDisconnectedAction());
    toast({
      title: 'Déconnexion réussie',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    push('/');
  };

  return (
    <>
      <NextLink
        as={`/mes-notifications`}
        passHref
        href={{
          pathname: '/mes-notifications',
        }}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          display={{
            base: 'inline-block', // show on mobile
            md: 'none',
          }}
        >
          Mes notifications
          {notifications > 0 && (
            <NotificationItem>{notifications}</NotificationItem>
          )}
        </MenuItem>
      </NextLink>

      <NextLink
        as={`/mes-messages`}
        passHref
        href={{
          pathname: '/mes-messages'
        }}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          display={{
            base: 'inline-block', // show on mobile
            md: 'none', // hide on desktop
          }}
        >
          Mes messages
          {messages > 0 && <NotificationItem>{notifications}</NotificationItem>}
        </MenuItem>
      </NextLink>

      <NextLink
        passHref
        href={{
          pathname: '/mon-profil',
        }}
        as={`/mon-profil`}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
        >
          Mes informations
        </MenuItem>
      </NextLink>

      <NextLink
        passHref
        href={{
          pathname: '/mes-publications',
        }}
        as={`/mes-publications`}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
        >
          Mes publications
        </MenuItem>
      </NextLink>
      <NextLink
        passHref
        href={{
          pathname: '/mes-discussions-de-groupe',
        }}
        as={`/mes-discussions-de-groupe`}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
        >
          Mes discussions de groupe
        </MenuItem>
      </NextLink>
      <NextLink
        passHref
        href={{
          pathname: '/mes-webinars',
        }}
        as={`/mes-webinars`}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
        >
          Mes webinaires
        </MenuItem>
      </NextLink>
      <NextLink
        passHref
        href={{
          pathname: '/mes-collegues',
        }}
        as={`/mes-collegues`}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
        >
          Mes collègues
        </MenuItem>
      </NextLink>
      <MenuDivider />
      <NextLink
        passHref
        href={{
          pathname: '/nous-contacter',
        }}
        as={`/nous-contacter`}
      >
        <MenuItem
          as={'a'}
          _hover={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
          _focus={{
            backgroundColor: 'brand.100',
            color: 'white',
          }}
        >
          Contacter IFprofs
        </MenuItem>
      </NextLink>
      {(isWorldOrganiser || isCountryOrganiser) && (
        <NextLink
          passHref
          href={{
            pathname: isWorldOrganiser ? '/admin' : '/country/admin',
            query: isWorldOrganiser ? {} : { country: user.country.code },
          }}
          as={`/${isCountryOrganiser ? `${user.country.code}/` : ''}admin`}
        >
          <MenuItem
            as={'a'}
            backgroundColor="brand.10"
            color="brand.100"
            borderTop="solid 1px white"
            borderBottom="solid 1px white"
            _hover={{
              backgroundColor: 'white',
            }}
            _focus={{
              backgroundColor: 'white',
            }}
            icon={<PiGearSixBold />}
          >
            Administration
          </MenuItem>
        </NextLink>
      )}
      <MenuItem
        onClick={() => logout()}
        _hover={{
          backgroundColor: 'brand.100',
          color: 'white',
        }}
        _focus={{
          backgroundColor: 'brand.100',
          color: 'white',
        }}
      >
        Se déconnecter
      </MenuItem>
    </>
  );
}
