import Modal from '@components/presentational/CustomModal/Modal';
import ModalHeader from '@components/presentational/CustomModal/ModalHeader';
import ModalBody from '@components/presentational/CustomModal/ModalBody';
import { Box, Button, Divider, Text, VStack } from '@chakra-ui/react';
import LoginForm from '@components/app/LoginForm';
import FormElement from '@components/presentational/Form/FormElement';
import React from 'react';
import NextLink from 'next/link';

export default function LoginModal({ onClose, onLoginSuccess }) {
  return (
    <Modal
      isOpen
      onClose={onClose}
      isCentered
      size={{ base: 'full', sm: 'sm' }}
    >
      <ModalHeader>
        <Text
          fontSize="1.25rem"
          letterSpacing={{ base: '-.25px', sm: 'revert' }}
        >
          Vivez l’expérience IFprofs
        </Text>
      </ModalHeader>
      <ModalBody>
        <VStack
          spacing="1rem"
          mt={{ base: '1rem', md: '1.5rem' }}
          align="stretch"
        >
          <LoginForm
            onSubmitEnd={user => {
              onLoginSuccess(user);
              onClose();
            }}
          />
          <Divider my=".125rem" />
          <Text textAlign="center">Pas encore inscrit(e) ?</Text>
          <Box textAlign="center" mt=".5rem" mb="1rem">
            <FormElement label="Créer un compte" isLabelVisuallyHidden>
              <NextLink
                as={`/s-inscrire`}
                passHref
                href={{
                  pathname: '/s-inscrire',
                }}
              >
                <Button size="sm" as={'a'}>
                  Créer un compte
                </Button>
              </NextLink>
            </FormElement>
          </Box>
        </VStack>
      </ModalBody>
    </Modal>
  );
}
