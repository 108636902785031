import { Formik, FormikProps } from 'formik';
import React from 'react';
import FormElement from '@components/presentational/Form/FormElement';
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { login } from '@/api/user';
import isEmail from 'validator/lib/isEmail';
import TextInput from '@components/app/form/TextInput';
import { useRouter } from 'next/router';
import { userLoggedAction } from '@/redux/modules/authentication';
import { useDispatch } from 'react-redux';
import { User } from '@/types/core/User';
import NextLink from 'next/link';
import { PiArrowRightThin } from 'react-icons/pi';

interface FormValues {
  formLogin: string;
  formPassword: string;
}

function FormikForm({
  submitCount,
  isValid,
  isValidating,
  isSubmitting,
  errors,
  handleSubmit,
  values,
}: FormikProps<FormValues>) {
  return (
    <form
      onSubmit={evt => {
        evt.preventDefault();
        handleSubmit(evt);
      }}
      noValidate
    >
      <VStack spacing="1rem" align="stretch">
        <TextInput
          name={'formLogin'}
          type={'email'}
          label={'Adresse email'}
          required
        />
        <TextInput
          label="Mot de passe"
          name={'formPassword'}
          type={'password'}
          required
        />
        <Stack direction={{ base: 'column', md: 'row' }} align="center">
          <Box ml={{ md: 'auto' }} mt=".5rem" order={{ md: 2 }}>
            <FormElement label="Se connecter" isLabelVisuallyHidden>
              <Button size="sm" type={'submit'} isLoading={isSubmitting}>
                Se connecter
              </Button>
            </FormElement>
          </Box>
          <Text>
            <NextLink
              as={`/mot-de-passe`}
              passHref
              href={{
                pathname: '/mot-de-passe',
              }}
            >
              <Link
                order={{ md: 1 }}
                fontSize=".75rem"
                textDecoration="underline"
              >
                <Flex as="span" align="end" gap=".5ch">
                  <Text as="span" display="inline-block" mt=".0625rem">
                    <Icon as={PiArrowRightThin} />
                  </Text>
                  <Text as="span" display="inline-block" mb=".0625rem">
                    Mot de passe oublié ?
                  </Text>
                </Flex>
              </Link>
            </NextLink>
          </Text>
        </Stack>
      </VStack>
    </form>
  );
}

export default function LoginForm({
  onSubmitEnd,
  redirectAfterLogin = false,
}: {
  onSubmitEnd?: (user?: User) => any;
  redirectAfterLogin?: boolean;
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const toast = useToast();

  const initialValues: FormValues = {
    formLogin: '',
    formPassword: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={data => {
        let errors: { formLogin?: string[] } = {};

        if (data.formLogin.length > 0 && !isEmail(data.formLogin)) {
          errors.formLogin = [
            "Cette valeur n'est pas une adresse email valide",
          ];
        }

        return errors;
      }}
      onSubmit={async (values, formActions) => {
        let response: Response;

        try {
          response = await login({
            email: values.formLogin,
            password: values.formPassword,
          });
        } catch (err) {
          formActions.setSubmitting(false);
          return;
        }

        if (!response.ok) {
          if (response.status === 400) {
            const responseExceptionData = await response.json();
            if (
              responseExceptionData.exception.className ===
              'LoginWithUnfinishedSignUpException'
            ) {
              // TODO : redirect to UnfinishedSignup when this page exist (without country)
            }
          }
          toast({
            title: 'Erreur lors de la connexion',
            description: 'Vos identifiants sont incorrects',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          formActions.setSubmitting(false);
          return;
        }

        const responseData = await response.json();

        dispatch(userLoggedAction(responseData.user));

        if (onSubmitEnd) {
          onSubmitEnd(responseData.user);
        }

        if (router.query.backPathname && router.query.backAsPath) {
          const savedQuery = {};
          for (let queryEntry of Object.entries(router.query)) {
            if (queryEntry[0].startsWith('savedQuery')) {
              savedQuery[queryEntry[0].slice(10)] = queryEntry[1];
            }
          }

          router.push(
            {
              pathname: router.query.backPathname as string,
              query: savedQuery,
            },
            router.query.backAsPath as string
          );
          return;
        }
        // Redirect to home page after login if we are not into modal
        if (redirectAfterLogin) {
          return router.push('/');
        }
      }}
    >
      {formProps => {
        return <FormikForm {...formProps} />;
      }}
    </Formik>
  );
}
