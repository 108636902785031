import LogoInstitutFrancais from '@/components/presentational/img/v2024/logo-institut-francais-white.svg';
import LogoRepubliqueFrancaise from '@/components/presentational/img/v2024/republique-francaise.svg';
import ArrowUp from '@/components/presentational/img/v2024/ArrowUp.svg';
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Text,
  useTheme,
  VisuallyHidden,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import UIWidth from '@components/presentational/UIWidth';

export default function Footer({
  isUserConnected = null,
  smallScreensHaveASecondaryNavAtBottom = null,
}) {
  const bottomPaddingForSmallScreens =
    isUserConnected && smallScreensHaveASecondaryNavAtBottom ? '4rem' : 0;

  const theme = useTheme();

  const CustomLink = ({ label, href }) => {
    return (
      <Link
        as={NextLink}
        href={href}
        _hover={{
          textDecoration: 'underline',
        }}
      >
        <Text textTransform="uppercase">{label}</Text>
      </Link>
    );
  };

  const CustomPipe = () => {
    return (
      <ListItem
        mx={['0.25rem', '0.75rem', null, '1rem']}
        display={['none', 'inline-block']}
      >
        |
      </ListItem>
    );
  };

  const SocialLink = ({ url, children }) => {
    return (
      <Link
        href={url}
        isExternal
        background="brand.100"
        color="white"
        transition="opacity .3s"
        _hover={{
          opacity: 0.6,
        }}
      >
        {children}
      </Link>
    );
  };

  return (
    <Box
      width="100%"
      bg="brand.100"
      borderTop="1px solid"
      borderColor="white"
      position="relative"
      height="fit-content"
      color="white"
      fontSize={['0.65rem', '0.75rem']}
      py={['2rem', null, '3rem']}
      sx={{
        '@media (max-width: 767px)': {
          paddingBottom: bottomPaddingForSmallScreens,
        },
      }}
    >
      <UIWidth>
        <Flex
          maxWidth={theme.uiWidth}
          mx="auto"
          direction={['column', 'row']}
          justify="space-between"
          gap="2.5rem"
        >
          <Flex
            direction={['column', null, 'row']}
            width={['100%', 'fit-content']}
            align="center"
            justify="flex-start"
            gap={['2rem', '1.5rem']}
          >
            <Image
              src={LogoRepubliqueFrancaise.src}
              alt="Logo de la République Française"
              height={['6.5rem', '6rem', '7rem']}
              flexShrink={{ md: 0 }}
            />
            <Link
              href="https://www.institutfrancais.com/"
              isExternal
              flexShrink={{ md: 0 }}
            >
              <VisuallyHidden>
                Visiter le site de l’Institut français
              </VisuallyHidden>
              <Image
                src={LogoInstitutFrancais.src}
                alt="Logo de l’Institut français"
                height={['5.5rem', '6.5rem']}
                width={{ md: '11rem' }}
              />
            </Link>
          </Flex>

          <Flex
            width="100%"
            direction="column"
            justify={['center', null, 'space-between']}
            align={{ sm: 'flex-end' }}
            gap={['1.5rem', '1rem']}
          >
            <Text
              fontSize={{ base: '0.95rem', md: '1.15rem' }}
              textAlign="center"
            >
              Institut français, tous droits réservés {new Date().getFullYear()}
            </Text>

            <Box as="nav">
              <List
                display="flex"
                flexDirection={['column', 'row']}
                justifyContent={{ sm: 'flex-end' }}
                alignItems="center"
                flexWrap="wrap"
                gap={['1rem', 0]}
              >
                <ListItem>
                  <NextLink href={'/qui-sommes-nous'} passHref>
                    <Link
                      as={'a'}
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      <Text textTransform="uppercase">
                        Qui sommes-nous&nbsp;?
                      </Text>
                    </Link>
                  </NextLink>
                </ListItem>
                <CustomPipe />
                <ListItem>
                  <NextLink href={'/faq'} passHref>
                    <Link
                      as={'a'}
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      <Text
                        textTransform="uppercase"
                        title="Foire Aux Questions"
                      >
                        FAQ
                      </Text>
                    </Link>
                  </NextLink>
                </ListItem>
                <CustomPipe />
                {/*<ListItem>*/}
                {/*  <CustomLink label="Devenir partenaire" href="#" />*/}
                {/*</ListItem>*/}
                {/*<CustomPipe />*/}
                <ListItem>
                  <NextLink href={'/kit-de-communication'} passHref>
                    <Link
                      as={'a'}
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      <Text textTransform="uppercase">
                        Kit de communication
                      </Text>
                    </Link>
                  </NextLink>
                </ListItem>
              </List>

              <List
                as={Flex}
                direction={['column', 'row']}
                justify={{ sm: 'flex-end' }}
                align="center"
                wrap="wrap"
                gap={['1rem', 0]}
                mt={['1rem', 0]}
              >
                <ListItem>
                  <NextLink href={'/politique-de-confidentialite'} passHref>
                    <Link
                      as={'a'}
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      <Text textTransform="uppercase">
                        Politique de confidentialité
                      </Text>
                    </Link>
                  </NextLink>
                </ListItem>
                <CustomPipe />
                <ListItem>
                  <NextLink
                    href={'/conditions-generales-d-utilisation'}
                    passHref
                  >
                    <Link
                      as={'a'}
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      <Text
                        textTransform="uppercase"
                        title="Conditions Générales d'Utilisation"
                      >
                        CGU
                      </Text>
                    </Link>
                  </NextLink>
                </ListItem>
                <CustomPipe />
                <ListItem>
                  <NextLink href={'/mentions-legales'} passHref>
                    <Link
                      as={'a'}
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      <Text textTransform="uppercase">Mentions légales</Text>
                    </Link>
                  </NextLink>
                </ListItem>
              </List>
            </Box>

            <Flex
              direction={['column', 'row']}
              justify="space-between"
              align="center"
              gap={['1rem', '5rem']}
              mt={{ sm: '0.75rem' }}
            >
              <Flex align="center" gap="2rem">
                <SocialLink url="https://www.facebook.com/IFprofs">
                  <VisuallyHidden>
                    Visiter la page Facebook de l’Institut français
                  </VisuallyHidden>
                  <svg
                    width="18"
                    height="24"
                    viewBox="0 0 18 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Page facebook de l'institut français</title>
                    <path
                      d="M16.4463 13.5L17.3351 9.15656H11.7782V6.33797C11.7782 5.14969 12.5544 3.99141 15.0432 3.99141H17.5694V0.293438C17.5694 0.293438 15.2769 0 13.085 0C8.5088 0 5.51754 2.08031 5.51754 5.84625V9.15656H0.430664V13.5H5.51754V24H11.7782V13.5H16.4463Z"
                      fill="currentColor"
                    />
                  </svg>
                </SocialLink>

                <SocialLink url="https://www.youtube.com/@iflanguefrancaise2095">
                  <VisuallyHidden>
                    Visiter la page Youtube de l’Institut français
                  </VisuallyHidden>
                  <svg
                    width="27"
                    height="18"
                    viewBox="0 0 27 18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Page Youtube de l’Institut français</title>
                    <path
                      d="M25.7653 2.81639C25.4709 1.7078 24.6034 0.834703 23.502 0.538406C21.5056 0 13.5002 0 13.5002 0C13.5002 0 5.4949 0 3.49845 0.538406C2.39702 0.83475 1.52956 1.7078 1.23513 2.81639C0.700195 4.82578 0.700195 9.01819 0.700195 9.01819C0.700195 9.01819 0.700195 13.2106 1.23513 15.22C1.52956 16.3286 2.39702 17.1653 3.49845 17.4616C5.4949 18 13.5002 18 13.5002 18C13.5002 18 21.5055 18 23.502 17.4616C24.6034 17.1653 25.4709 16.3286 25.7653 15.22C26.3002 13.2106 26.3002 9.01819 26.3002 9.01819C26.3002 9.01819 26.3002 4.82578 25.7653 2.81639ZM10.882 12.8246V5.2118L17.5729 9.01828L10.882 12.8246Z"
                      fill="currentColor"
                    />
                  </svg>
                </SocialLink>

                <SocialLink url="https://fr.linkedin.com/company/ifparis">
                  <VisuallyHidden>
                    Visiter la page LinkedIn de l’Institut français
                  </VisuallyHidden>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Page LinkedIn de l’Institut français</title>
                    <path
                      d="M4.70062 21H0.346875V6.97971H4.70062V21ZM2.52141 5.06721C1.12922 5.06721 0 3.91408 0 2.52189C9.96468e-09 1.85318 0.265647 1.21185 0.738503 0.738991C1.21136 0.266136 1.85269 0.000488281 2.52141 0.000488281C3.19012 0.000488281 3.83145 0.266136 4.30431 0.738991C4.77716 1.21185 5.04281 1.85318 5.04281 2.52189C5.04281 3.91408 3.91313 5.06721 2.52141 5.06721ZM20.9953 21H16.6509V14.175C16.6509 12.5485 16.6181 10.4625 14.3873 10.4625C12.1237 10.4625 11.7769 12.2297 11.7769 14.0578V21H7.42781V6.97971H11.6034V8.89221H11.6644C12.2456 7.79064 13.6655 6.62814 15.7838 6.62814C20.19 6.62814 21 9.52971 21 13.2985V21H20.9953Z"
                      fill="currentColor"
                    />
                  </svg>
                </SocialLink>
              </Flex>

              <Box mb={{ base: '1rem', md: 0 }}>
                <NextLink
                  href={'/nous-contacter'}
                  as={'/nous-contacter'}
                  passHref
                >
                  <Button as={'a'} variant="whiteOutline" size="sm">
                    Nous contacter
                  </Button>
                </NextLink>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </UIWidth>

      <Flex
        bg="white"
        color="black"
        align="center"
        justify="center"
        cursor="pointer"
        borderStyle="solid"
        position="absolute"
        borderColor="brand.100"
        _hover={{ bg: 'brand.10' }}
        transition="background 0.25s ease-in-out"
        right={['1.5rem', '3rem', null, '4rem']}
        borderWidth={['2px', '3.5px', '4px', '5px']}
        width={['2.75rem', '3.5rem', '4rem', '5.5rem']}
        height={['2.75rem', '3.5rem', '4rem', '5.5rem']}
        top={['-1.375rem', '-1.75rem', '-2rem', '-2.75rem']}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        title="Retour en haut de page"
      >
        <>
          <VisuallyHidden>Retour en haut de page</VisuallyHidden>
          <Image
            src={ArrowUp.src}
            alt="Retour en haut de page"
            width="60%"
            height="60%"
          />
        </>
      </Flex>
    </Box>
  );
}
