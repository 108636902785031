import React from 'react';
import LogoInstitutFrancais from '@/components/presentational/img/v2024/LogoInstitutFrancais.svg';
import { Flex, Img, Button } from '@chakra-ui/react';
import NextLink from 'next/link';

export default function HeaderLogos() {
  return (
    <Flex
      gap={[0, null, '2.25rem']}
      alignItems={{ md: 'flex-start' }}
      minHeight="3.9375rem"
      ml={{ base: '1rem', md: 0 }}
      zIndex={{ md: 'dropdown' }}
    >
      <NextLink href={'/'} as={'/'} passHref>
        <Button
          as="a"
          variant="link"
          title="Aller à la page d'accueil"
          textDecoration="none"
          display={['none', null, 'flex']}
          height="auto"
          marginTop="1.75rem"
          borderRadius={0}
        >
          <Img
            src={LogoInstitutFrancais.src}
            alt="Logo Institut Français"
            width="11.875rem"
            height="7.28rem"
          />
        </Button>
      </NextLink>
      <NextLink href={'/'} as={'/'} passHref>
        <Button
          as="a"
          variant="link"
          title="Aller à la page d'accueil"
          textDecoration="none"
          display="flex"
          height={['3.9375rem', null, 'auto']}
          fontSize={['1.5rem', null, '2rem']}
          fontWeight="600"
          borderRadius={0}
          mt={{ base: '-.25rem', md: '3.5rem' }}
          whiteSpace="nowrap"
        >
          IFprofs
        </Button>
      </NextLink>
    </Flex>
  );
}
