import React from 'react';
import { Box, ListItem, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

export default function HeaderSubNavItem({ href, rub, isActive, children }) {
  let markerColor = 'brand.100';
  if (rub == 'formations') {
    markerColor = 'menthe.100';
  }
  if (rub == 'communautes') {
    markerColor = 'tournesol.100';
  }
  return (
    <ListItem display={{ md: 'block' }} height={{ md: '100%' }}>
      <Link as={NextLink} href={href} passHref>
        <Box
          as={'a'}
          display={{ md: 'flex' }}
          flexDirection={{ md: 'column' }}
          alignItems={{ md: 'center' }}
          justifyContent={{ md: 'space-between' }}
          height={{ md: '100%' }}
          color={isActive ? 'brand.100' : null}
          fontWeight={isActive ? 'bold' : null}
          _hover={{
            textDecoration: 'none',
            color: 'brand.100',
          }}
        >
          <Box
            as="span"
            display={{ base: 'none', md: 'block' }}
            backgroundColor="transparent"
            width="1.875rem"
            height=".625rem"
            overflow="hidden"
            content=""
          />
          <Box h="2rem" lineHeight="1.5625rem">
            {children}
          </Box>
          <Box
            as="span"
            display={{ base: 'none', md: 'block' }}
            backgroundColor={isActive ? markerColor : 'transparent'}
            width="1.875rem"
            height=".625rem"
            overflow="hidden"
            content=""
          />
        </Box>
      </Link>
    </ListItem>
  );
}
