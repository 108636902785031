import React, { ReactNode } from 'react';
import { Box, Icon, Link } from '@chakra-ui/react';
import { PiCaretDownBold } from 'react-icons/pi';
import { LinkProps } from 'next/dist/client/link';
import { UrlObject } from 'url';
import NextLink from 'next/link';

type HeaderMainNavItemProps = Omit<LinkProps, 'href'> & {
  mainNavItemId?: string;
  subNavID?: string;
  label: string;
  isActive: boolean;
  isCaretVisible?: ReactNode;
  baseColor: string;
  href: string | UrlObject;
  onClickToggleSubNav: () => void; // Fonction pour activer/désactiver le sous-menu
};

export default function HeaderMainNavItem({
  mainNavItemId,
  subNavID,
  label,
  isActive,
  isCaretVisible,
  baseColor,
  href,
  onClickToggleSubNav,
  ...props
}: HeaderMainNavItemProps) {
  const activeButtonBg = baseColor + '.25';

  let w = '7.25rem'; /* Ceci est utilisé pour éviter les changements de largeur lorsque le label est affiché en gras ou en poids normal */
  if (label == 'Communautés') {
    w = '9rem';
  }

  return (
    <Box
      borderBottom={{ base: 'solid 1px', md: 'none' }}
      borderBottomColor="black.25"
    >
      {label == 'Ressources' ?
        <NextLink passHref href={href} {...props}>
          <Link
            textDecoration="none"
            width="100%"
            fontWeight="normal"
            id={mainNavItemId}
            data-subnav-id={subNavID}
            display={{ base: 'flex', md: 'none' }} // Mobile 'Ressources' => clickable
            alignItems="center"
            justifyContent="space-between"
            textAlign="center"
            px="1rem"
            minHeight="4.5rem"
            fontSize="1.125rem"
            backgroundColor={isActive ? activeButtonBg : 'white'}
            borderRadius={0}
            position="relative"
            _hover={{
              backgroundColor: activeButtonBg,
            }}
          >
            {label}
            {isCaretVisible && (
              <Icon
                as={PiCaretDownBold}
                display={{ md: 'none' }}
                transform={`rotate(${isActive ? '0' : '180'}deg)`}
              />
            )}
          </Link>
        </NextLink>
        :
        <Link
          textDecoration="none"
          width="100%"
          fontWeight="normal"
          id={mainNavItemId}
          data-subnav-id={subNavID}
          display={{ base: 'flex', md: 'none' }} // Mobile others, this acts as a switch
          alignItems="center"
          justifyContent="space-between"
          textAlign="center"
          px="1rem"
          minHeight="4.5rem"
          fontSize="1.125rem"
          backgroundColor={isActive ? activeButtonBg : 'white'}
          borderRadius={0}
          position="relative"
          _hover={{
            backgroundColor: activeButtonBg,
          }}
          onClick={onClickToggleSubNav}
        >
          {label}
          {isCaretVisible && (
            <Icon
              as={PiCaretDownBold}
              display={{ md: 'none' }}
              transform={`rotate(${isActive ? '0' : '180'}deg)`}
            />
          )}
        </Link>
      }
      <NextLink passHref href={href} {...props}>
        <Link
          as={'a'}
          display={{ base: 'none', md: 'block' }}
          textAlign="center"
          p="0 .5rem"
          fontSize="1.125rem"
          borderRadius="full"
          position="relative"
          fontWeight={isActive ? 'bold' : 'normal'}
          color={isActive ? 'brand.100' : null}
          width={w}
          _hover={{
            color: 'brand.100',
          }}
        >
          {label}
          {isActive && (
            <Box
              as="span"
              display={{ base: 'none', md: 'block' }}
              position="absolute"
              left="50%"
              bottom="-1.53125rem"
              backgroundColor={baseColor + '.100'}
              width="1.875rem"
              height=".625rem"
              overflow="hidden"
              content=""
              transform="translate3d(-50%, 0, 0)"
            />
          )}
        </Link>
      </NextLink>
    </Box>
  );
}
