const groupLink = require('../groups/groupLink');
const getRoutePrefixFromPublicationType = require('./getRoutePrefixFromPublicationType');

function publicationLink(publication) {
  if (publication.__TYPE__ === 'discussion')
    return `${groupLink(publication.group)}/discussions/${publication.slug}/${
      publication.id
    }`;
  else
    return `/${getRoutePrefixFromPublicationType(publication.__TYPE__)}/${
      publication.slug
    }/${publication.id}`;
}

module.exports = publicationLink;
