import React, {useState} from "react";
import { PiMagnifyingGlassThin } from "react-icons/pi";
import {
  Button,
  VisuallyHidden,
  Icon,
  FormLabel,
  Input,
  FormControl,
  InputGroup,
  InputRightElement, Flex,
} from "@chakra-ui/react";

export default function HeaderSearchEngine({onSearchSubmit, isVisible}) {
  const [search, setSearch] = useState('');
  return (
    <Flex
      as="form"
      alignItems="center"
      display={{
        base: isVisible ? 'flex' : 'none',
        md: 'flex',
      }}
      flexBasis={{md: '18rem'}}
      flexGrow="grow"
      pt={{md: '.25rem'}}
      onSubmit={e => {
        e.preventDefault();
        if (onSearchSubmit) {
          onSearchSubmit(search);
        }
      }}
    >

    <FormControl
      position={['absolute', null, 'revert']}
      top={['4rem', null, 'revert']}
      left={[0, null, 'revert']}
      right={[0, null, 'revert']}
      p={['1rem', null, 'revert']}
      flexBasis={{ md: '18rem' }}
      backgroundColor="white"
      zIndex="docked"
    >
      <VisuallyHidden as={FormLabel}>Rechercher sur le site</VisuallyHidden>
      <InputGroup size="sm">
        <Input
          pr="2.5rem"
          type="search"
          placeholder="Rechercher dans IFprofs"
          fontSize=".6875rem"
          title="Rechercher dans IFprofs"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
        />
        <InputRightElement width="2rem">
          <Button
            p=".25rem"
            aspectRatio="1 / 1"
            minWidth="revert"
            height="auto"
            type="submit"
          >
            <VisuallyHidden>Lancer la recherche</VisuallyHidden>
            <Icon as={PiMagnifyingGlassThin} boxSize={4} color="white.100" />
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
    </Flex>
  );
}