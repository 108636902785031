import { ModalBody as ChakraModalBody } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface ModalBodyProps {
  children: React.ReactNode
}

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(({ children }, ref) => {
  return (
    <ChakraModalBody ref={ref} py="1rem" width="100%">
      {children}
    </ChakraModalBody>
  );
});

export default ModalBody;
