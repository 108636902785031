import React, { useMemo, useState } from 'react';

import HeaderTogglableItems from '@components/presentational/HeaderTogglableItems';
import HeaderMainMenu from '@components/presentational/HeaderMainMenu';
import HeaderSubNavItem from '@components/presentational/HeaderSubNavItem';
import { useSelector } from 'react-redux';
import { User } from '@/types/core/User';
import HeaderMyAccountMenu from '@components/HeaderMyAccountMenu';
import HeaderBase from '@components/HeaderBase';
import HeaderMainMenuItem from '@components/HeaderMainMeniItem';
import { useRouter } from 'next/router';
import { useGoogleTagManager } from '@components/app/hooks/useGTM';
import getConfig from 'next/config';

export enum NavCategory {
  Ressources = 'ressources',
  Formations = 'formations',
  Communities = 'communautes',
}

export enum NavSubCategory {
  COURSES = 'COURSES',
  WEBINARS = 'WEBINARS',
  PARTNERS = 'PARTNERS',
  EXPERTS = 'EXPERTS',
  MY_COMMUNITY = 'MY_COMMUNITY',
  MY_GROUPS = 'MY_GROUPS',
  MEMBERS = 'MEMBERS',
  SEARCH = 'SEARCH',
}

export interface NavItem {
  label: string;
  url: string;
  tab: NavCategory;
  baseColor: 'menthe' | 'tuile' | 'tournesol';
  items?: NavSubItem[];
}

interface NavSubItem {
  label: string;
  url: string;
  tab?: string;
}
interface Props {
  mainNavItemActive?: NavCategory;
  subNavItemActive?: NavSubCategory;
  children?: React.ReactNode;
}

export default function Header({
  mainNavItemActive,
  subNavItemActive,
  children,
}: Props) {
  const expertiseEnabled = getConfig().publicRuntimeConfig.EXPERTISE_ENABLED;

  const navItems = useMemo(() => {
    const courseNav = [
      {
        label: 'Les formations IFprofs',
        url: '/formations',
        tab: NavSubCategory.COURSES,
      },
      {
        label: 'Les webinaires IFprofs',
        url: '/webinars/replays',
        tab: NavSubCategory.WEBINARS,
      },
    ];

    if (expertiseEnabled) {
      courseNav.push({
        label: "L'annuaire des experts",
        url: '/formations/annuaire-des-experts',
        tab: NavSubCategory.EXPERTS,
      });
    }

    return [
      {
        label: 'Ressources',
        url: '/ressources',
        tab: NavCategory.Ressources,
        baseColor: 'tuile',
      },
      {
        label: 'Formations',
        url: '/formations',
        tab: NavCategory.Formations,
        baseColor: 'menthe',
        items: courseNav,
      },
      {
        label: 'Communautés',
        url: '/communautes',
        tab: NavCategory.Communities,
        baseColor: 'tournesol',
        items: [
          {
            label: 'Ma communauté',
            url: '/communautes',
            tab: NavSubCategory.MY_COMMUNITY,
          },
          {
            label: 'Mes groupes',
            url: '/communautes/groupes',
            tab: NavSubCategory.MY_GROUPS,
          },
          {
            label: 'Tous les membres',
            url: '/communautes/membres',
            tab: NavSubCategory.MEMBERS,
          },
        ],
      },
    ];
  }, [expertiseEnabled]);

  const user: User | null = useSelector(
    (state: any) => state.authentication.user
  );

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);
  const [isSearchEngineVisible, setIsSearchEngineVisible] = useState<boolean>(
    false
  );

  const { sendDataToGTM } = useGoogleTagManager();
  const router = useRouter();

  return (
    <>
      <HeaderBase mainNavItemActive={mainNavItemActive}>
        <HeaderTogglableItems
          isBurgerMenuOpen={isBurgerMenuOpen}
          onToggleSearchEngineVisibility={() => {
            setIsSearchEngineVisible(!isSearchEngineVisible);
          }}
          onToggleBurgerMenuVisibility={() => {
            setIsBurgerMenuOpen(!isBurgerMenuOpen);
          }}
        >
          <HeaderMyAccountMenu
            isUserLogged={!!user}
            userName={user?.displayName}
            userAvatar={
              user?.avatar?.filename
                ? `/data/avatars/${user?.avatar?.filename}`
                : null
            }
            unreadNotifications={user?.unreadAlerts}
            unreadMessages={user?.unreadMessages}
          />
        </HeaderTogglableItems>
        <HeaderMainMenu
          isBurgerMenuOpen={isBurgerMenuOpen}
          isSearchEngineVisible={isSearchEngineVisible}
          onSearchSubmit={async value => {
            sendDataToGTM({
              data: {
                event: 'search',
                event_action: 'Recherche interne',
                search_term: value,
              },
            });
            await router.push(
              { pathname: '/recherche', query: { s: value } },
              `/recherche?s=${value}`
            );
          }}
        >
          {navItems.map((navItem, index) => (
            <HeaderMainMenuItem
              key={index}
              label={navItem.label}
              href={navItem.url}
              baseColor={navItem.baseColor}
              isActive={navItem.tab === mainNavItemActive}
            >
              {navItem.items && navItem.items.length > 0
                ? navItem.items.map((subItem, index) => (
                    <HeaderSubNavItem
                      key={index}
                      href={subItem.url}
                      rub={mainNavItemActive}
                      isActive={subNavItemActive === subItem.tab}
                    >
                      {subItem.label}
                    </HeaderSubNavItem>
                  ))
                : null}
              {navItem.tab === mainNavItemActive && children}
            </HeaderMainMenuItem>
          ))}
        </HeaderMainMenu>
      </HeaderBase>
    </>
  );
}
