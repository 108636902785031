import { ModalHeader as ChakraModalHeader } from '@chakra-ui/react';

export default function ModalHeader({ children }) {
  return (
    <ChakraModalHeader
      width="88.5%"
      pt="0.75rem"
      pb="0"
      color="brand.100"
      fontSize={['1rem', '', '1.25rem']}
    >
      {children}
    </ChakraModalHeader>
  );
}
