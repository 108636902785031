import {Box, Flex, List} from '@chakra-ui/react';
import React from "react";
import HeaderSearchEngine from "./HeaderSearchEngine";

export default function HeaderMainMenu({children, isBurgerMenuOpen, isSearchEngineVisible, onSearchSubmit}) {
  return <Flex gap={{md: '1.25rem'}} alignSelf={{md: 'stretch'}}>
    <Box
      display={[isBurgerMenuOpen ? 'block' : 'none', '', 'flex']}
      position={['absolute', null, 'revert']}
      flexShrink={0}
      flexGrow={1}
      alignItems={[null, null, 'center']}
      top={['4rem', null, 'revert']}
      left={[0, null, 'revert']}
      right={[0, null, 'revert']}
      backgroundColor="primary.regular"
      order={[2, null, 'revert']}
      alignSelf={[null, null, 'stretch']}
    >
      <Box as="nav">
        <List
          display={[null, null, 'flex']}
          fontSize="sm"
          lineHeight={[8, null, 'normal']}
          alignItems={[null, null, 'stretch']}
          gap={{md: '2vw'}}
        >
          {children}
        </List>
      </Box>
    </Box>
      <HeaderSearchEngine onSearchSubmit={onSearchSubmit} isVisible={isSearchEngineVisible} />
  </Flex>
}