export default function useValidationForRequiredField(
  required,
  validate,
  requiredMessage
) {
  if (!required) {
    return validate;
  }

  return value => {
    let errors = [];

    if (
      !value ||
      value.length === 0 ||
      (value.trim && value.trim().length === 0)
    ) {
      errors.push(
        requiredMessage ? requiredMessage : 'Cette valeur ne doit pas être vide'
      );
    }

    if (validate) {
      let validateErrors = validate(value);
      if (validateErrors) {
        errors = errors.concat(validateErrors);
      }
    }

    return errors.length > 0 ? errors : undefined;
  };
}
