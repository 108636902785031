import React from 'react';
import { PiBellThin, PiEnvelopeThin, PiUserThin } from 'react-icons/pi';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useSelector } from 'react-redux';
import { ReduxRootState } from '@/types/store/ReduxRootState';
import { HeaderMenuItems } from '@components/HeaderMenuItems';
import LoginModal from '@components/LoginModal';
import { useRouter } from 'next/router';

interface HeaderMyAccountMenuProps {
  unreadNotifications: number;
  unreadMessages: number;
  isUserLogged: boolean;
  userName: string;
  userAvatar: string;
}
export default function HeaderMyAccountMenu({
  unreadNotifications,
  unreadMessages,
  isUserLogged,
  userName,
  userAvatar,
}: HeaderMyAccountMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { replace, pathname, query, asPath } = useRouter();

  const user = useSelector(
    (state: ReduxRootState) => state.authentication.user
  );

  return (
    <Box minHeight={{ md: '3rem' }} height={{ md: '3rem' }}>
      {isUserLogged && (
        <Box>
          <HStack>
            <NextLink
              as={`/mes-notifications`}
              passHref
              href={{
                pathname: '/mes-notifications',
              }}
            >
              <Button
                as={'a'}
                variant="link"
                display={{ base: 'none', md: 'inline-flex' }}
                color={unreadNotifications > 0 ? null : 'black.75'}
                aria-label={
                  unreadNotifications > 0
                    ? 'Vous avez ' +
                      unreadNotifications +
                      ' notification(s) non lue(s)'
                    : "Vous n'avez aucune notification en attente"
                }
                title={
                  unreadNotifications > 0
                    ? 'Vous avez ' +
                      unreadNotifications +
                      ' notification(s) non lue(s)'
                    : "Vous n'avez aucune notification en attente"
                }
                _hover={{ animation: 'ring 0.5s' }}
                css={`
                  @keyframes ring {
                    0% {
                      transform: rotate(0deg);
                    }
                    25% {
                      transform: rotate(-22.5deg);
                    }
                    50% {
                      transform: rotate(22.5deg);
                    }
                    75% {
                      transform: rotate(-22.5deg);
                    }
                    100% {
                      transform: rotate(0deg);
                    }
                  }
                `}
              >
                <Box as="span" display="block" position="relative">
                  <Icon as={PiBellThin} boxSize={8} />
                  {unreadNotifications > 0 && (
                    <Flex
                      as="span"
                      display="inline-flex"
                      color="white"
                      background="danger.100"
                      fontSize=".75rem"
                      lineHeight="1rem"
                      minWidth="1rem"
                      height="1rem"
                      borderRadius="full"
                      justifyContent="center"
                      alignItems="center"
                      fontFamily="sans-serif"
                      px=".25rem"
                      position="absolute"
                      top={0}
                      right={0}
                      whiteSpace="nowrap"
                    >
                      {unreadNotifications}
                    </Flex>
                  )}
                </Box>
              </Button>
            </NextLink>
            <NextLink
              as={`/mes-messages`}
              passHref
              href={{
                pathname: 'mes-messages',
              }}
            >
              <Button
                as={'a'}
                variant="link"
                display={{ base: 'none', md: 'inline-flex' }}
                color={unreadMessages > 0 ? null : 'black.75'}
                aria-label={
                  unreadMessages > 0
                    ? 'Vous avez ' + unreadMessages + ' message(s) non lu(s)'
                    : "Vous n'avez aucun message non lu"
                }
                title={
                  unreadMessages > 0
                    ? 'Vous avez ' + unreadMessages + ' message(s) non lu(s)'
                    : "Vous n'avez aucun message non lu"
                }
                _hover={{
                  animation: 'envelope-hover-animation 0.5s',
                }}
                css={`
                  @keyframes envelope-hover-animation {
                    0% {
                      transform: scale(1);
                    }
                    50% {
                      transform: scale(1.5);
                    }
                    100% {
                      transform: scale(1);
                    }
                  }
                `}
              >
                <Box as="span" display="block" position="relative">
                  <Icon as={PiEnvelopeThin} boxSize={8} />
                  {unreadMessages > 0 && (
                    <Flex
                      as="span"
                      display="inline-flex"
                      color="white"
                      background="danger.100"
                      fontSize=".75rem"
                      lineHeight="1rem"
                      minWidth="1rem"
                      height="1rem"
                      borderRadius="full"
                      justifyContent="center"
                      alignItems="center"
                      fontFamily="sans-serif"
                      px=".25rem"
                      position="absolute"
                      top={0}
                      right={0}
                      whiteSpace="nowrap"
                    >
                      {unreadMessages}
                    </Flex>
                  )}
                </Box>
              </Button>
            </NextLink>
            <Popover>
              <PopoverTrigger>
                <Button
                  as={IconButton}
                  aria-label="Mon compte"
                  title="Mon compte"
                  mr={['-0.5rem', '', '0']}
                  icon={
                    <Avatar
                      name={userName ? userName : 'Utilisateur Inconnu'}
                      src={userAvatar ? userAvatar : null}
                      size={{ base: 'sm', md: 'md' }}
                    />
                  }
                  variant="link"
                  height={{ base: '2.5rem', md: '3rem' }}
                  overflow="hidden"
                  boxSizing="content-box"
                  border="solid 4px transparent"
                  _hover={{
                    borderColor: 'brand.100',
                  }}
                />
              </PopoverTrigger>
              <Box zIndex="dropdown">
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <Menu>
                      <HeaderMenuItems
                        messages={unreadMessages}
                        notifications={unreadNotifications}
                      />
                    </Menu>
                  </PopoverBody>
                </PopoverContent>
              </Box>
            </Popover>
          </HStack>
        </Box>
      )}
      {!isUserLogged && (
        <>
          <Button
            variant="link"
            display="flex"
            aria-label="Se connecter ou s'inscrire"
            alignItems="center"
            onClick={onOpen}
            textDecoration="none"
          >
            <Icon as={PiUserThin} boxSize={8} />
            <Box
              as="span"
              display={['none', null, 'inline-block']}
              fontWeight="normal"
              fontSize="sm"
              ml={4}
            >
              Connexion
            </Box>
          </Button>
          {isOpen && (
            <LoginModal
              onClose={onClose}
              onLoginSuccess={user => {
                toast({
                  title: `Bienvenue ${user?.displayName}`,
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
                // We replace route for processing getInitialProps after login
                replace(
                  {
                    pathname: pathname,
                    query: query,
                  },
                  asPath
                );
              }}
            />
          )}
        </>
      )}
    </Box>
  );
}
