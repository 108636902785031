import { Field, getIn } from 'formik';
import React, { RefObject } from 'react';
import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  ListItem,
  UnorderedList,
  useTheme,
} from '@chakra-ui/react';
import useValidationForRequiredField from '@/form/useValidationForRequiredField';
import FormElement from '@components/presentational/Form/FormElement';
import { IconType } from 'react-icons';

interface Props extends InputProps {
  label?: string;
  id?: string;
  name: string;
  validate?: (value: string) => string | undefined;
  required?: boolean;
  requiredMessage?: string;
  isInline?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  isLabelVisuallyHidden?: boolean;
  isRequiredIndicatorNotDisplayed?: boolean;
  icon?: IconType;
}

export default function TextInput({
  id,
  label,
  name,
  validate,
  required,
  requiredMessage,
  isInline = false,
  inputRef,
  isLabelVisuallyHidden,
  isRequiredIndicatorNotDisplayed,
  icon,
  ...props
}: Props) {
  const validateForm = useValidationForRequiredField(
    required,
    validate,
    requiredMessage
  );
  const theme = useTheme();

  return (
    <Field name={name} validate={validateForm}>
      {({ field, form }) => {
        const errors = getIn(form.errors, name);
        const touched = getIn(form.touched, name);
        const hasError = errors && touched;

        let errorsComponents = null;
        if (errors) {
          errorsComponents = (
            <Flex flexDirection={'row'}>
              <UnorderedList>
                {errors.map(errorItem => (
                  <ListItem key={errorItem}>{errorItem}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
          );
        }

        return (
          <FormElement
            label={label}
            htmlFor={id ? id : name}
            isInvalid={hasError}
            isRequired={required}
            errorMessage={errorsComponents}
            isInline={isInline}
            isLabelVisuallyHidden={isLabelVisuallyHidden}
            isRequiredIndicatorNotDisplayed={isRequiredIndicatorNotDisplayed}
          >
            <InputGroup>
              {icon && (
                <InputLeftElement>
                  <Icon as={icon} />
                </InputLeftElement>
              )}
              <Input
                outlineOffset={-2}
                isRequired={required}
                {...field}
                value={field.value === null ? '' : field.value}
                id={id ? id : name}
                name={name}
                ref={inputRef}
                maxWidth={theme.maxFieldsWidth}
                {...props}
              />
            </InputGroup>
          </FormElement>
        );
      }}
    </Field>
  );
}
