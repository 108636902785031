import Head from 'next/head';
import LogoIfprofs from '../presentational/img/logo-ifprofs.jpg';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import dayjs from 'dayjs';
import resourceTypeTranslations from './publications/resourceTypeTranslations';
import publicationLink from './publications/publicationLink';
import userTranslations from '../../translations/user.fr.yml';
import groupTranslations from '../../translations/group.fr.yml';
import pedagogicalResourceTranslations from '../../translations/pedagogicalResource.fr.yml';
import newsTranslations from '../../translations/news.fr.yml';
import eventTranslations from '../../translations/event.fr.yml';
import PublicationCanonicalUrl from './publications/PublicationCanonicalUrl';
import { useSelector } from 'react-redux';

const host = getConfig().publicRuntimeConfig.HOST;

function getPublicationKeywords(publication) {
  return [
    ...(publication.disciplines
      ? publication.disciplines.map(
          discipline => userTranslations.discipline[discipline]
        )
      : []),
    ...(publication.themes
      ? publication.themes.map(theme => groupTranslations.subject[theme])
      : []),
    ...(publication.activityTypes
      ? publication.activityTypes.map(
          activityType =>
            pedagogicalResourceTranslations.activity_type[activityType]
        )
      : []),
    ...(publication.trainingGoals
      ? publication.trainingGoals.map(
          trainingGoal => groupTranslations.goal[trainingGoal]
        )
      : []),
    ...(publication.newsTypes
      ? publication.newsTypes.map(
          newsType => newsTranslations.news_type[newsType]
        )
      : []),
    ...(publication.eventTypes
      ? publication.eventTypes.map(
          eventType => eventTranslations.event_type[eventType]
        )
      : []),
  ];
}
function getGroupKeywords(group) {
  return [
    ...group.disciplines.map(
      discipline => userTranslations.discipline[discipline]
    ),
    ...group.subjects.map(subject => groupTranslations.subject[subject]),
    ...group.trainingGoals.map(
      trainingGoal => groupTranslations.goal[trainingGoal]
    ),
  ];
}
function getUserKeywords(user) {
  return [
    ...user.disciplines.map(
      discipline => userTranslations.discipline[discipline]
    ),
    ...user.jobs.map(job => userTranslations.jobs[job]),
  ];
}

export default function Metadata(props) {
  const router = useRouter();
  const currentCountry = useSelector(state => state.currentCountry.country);
  const publication =
    props.event || props.resource || props.news || props.article;
  const url =
    host + (publication ? publicationLink(publication) : router.asPath);
  const title = publication ? publication.title : props.title;
  const titleSuffix = ` | IFprofs${
    currentCountry ? ' ' + currentCountry.name : ''
  }`;
  const description = publication ? publication.abstract : props.description;
  const breadcrumb = publication
    ? [
        { id: '/', name: 'Accueil' },
        { id: null, name: publication.title },
      ]
    : props.breadcrumb;
  const keywords = useMemo(() => {
    let keywords = props.keywords ?? [];
    if (publication) {
      keywords = [...keywords, getPublicationKeywords(publication)];
    }
    if (props.group) {
      keywords = [...keywords, getGroupKeywords(props.group)];
    }
    if (props.user) {
      keywords = [...keywords, getUserKeywords(props.user)];
    }
    return keywords;
  }, [props.keywords, publication, props.group, props.user]);

  const image = useMemo(() => {
    if (props.user?.avatar?.filename) {
      return {
        src: `/data/avatars/${props.user.avatar.filename}`,
        width: 100,
        height: 100,
      };
    }
    if (props.event?.image?.filename) {
      return {
        src: `/data/event/images/${props.event.image.filename}`,
        width: 100,
        height: 100,
      };
    }
    return LogoIfprofs;
  }, [props.user, props.event]);

  const webpageJsonSchema = useMemo(() => {
    return JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      url,
      name: title + titleSuffix,
      description: description,
      inLanguage: 'French',
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumb.map((item, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@id': item.id ? host + item.id : url,
              name: item.name,
            },
          };
        }),
      },
    });
  }, [url, title, titleSuffix, description, breadcrumb]);

  const jsonSchema = useMemo(() => {
    let json = {
      '@context': 'http://schema.org',
      name: title + titleSuffix,
    };
    if (props.isDisconnectedHomepage) {
      json['@type'] = 'Website';
      json['name'] = 'IFprofs';
      json['url'] = url;
      json['about'] = "La communauté de l'éducation en français";
      json['abstract'] =
        "Communauté mondiale des professionnel·les de l'éducation francophone. Enrichissez vos pratiques professionnelles et échangez avec des collègues du monde entier.";
      json['image'] = host + image.src;
      json['sameAs'] = [
        'https://www.linkedin.com/company/ifprofs/',
        'https://www.facebook.com/IFprofs/',
        'https://twitter.com/IFprofs',
      ];
      json['funder'] = {
        '@type': 'Organization',
        name: 'Institut Français',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Paris, France',
          postalCode: '75015',
          streetAddress: '8-14 rue du Capitaine Scott',
        },
        sameAs: [
          'https://www.institutfrancais.com/',
          'https://fr.wikipedia.org/wiki/Institut_fran%C3%A7ais',
        ],
      };
    }
    if (props.event) {
      json['@type'] = 'Event';
      json['mainEntityOfPage'] = url;
      json['description'] = description;
      json['image'] = host + image.src;
      const eventStart = dayjs.utc(props.event.start).format('YYYY-MM-DD');
      json['startDate'] = eventStart;
      json['endDate'] = props.event.end
        ? dayjs.utc(props.event.end).format('YYYY-MM-DD')
        : eventStart;
      json['location'] = {
        '@type': 'Place',
        name: props.event.location.name,
        address: {
          '@type': 'PostalAddress',
          addressCountry: props.event.location.country,
          addressLocality: props.event.location.town,
          postalCode: props.event.location.postcode,
          streetAddress:
            props.event.location.address +
            ' - ' +
            props.event.location.address2,
        },
      };
    }
    if (props.resource) {
      json['@type'] = 'CreativeWork';
      json['mainEntityOfPage'] = url;
      json['learningResourceType'] =
        resourceTypeTranslations[props.resource.__TYPE__];
      json['abstract'] = description;
      json['author'] = props.resource.author?.displayName;
      json['publisher'] = 'IFprofs';
      json['datePublished'] = dayjs
        .utc(props.resource.publishedAt)
        .format('YYYY-MM-DD');
      json['inLanguage'] = 'French';
      json['keywords'] = keywords;
    }

    return JSON.stringify(json);
  }, [
    props.isDisconnectedHomepage,
    props.event,
    props.resource,
    image,
    url,
    description,
    keywords,
  ]);

  return (
    <Head>
      <title>{title + titleSuffix}</title>
      <meta name="description" content={description} key="description" />
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(',')} key="keywords" />
      )}
      <meta property="og:type" content="website" key="og_type" />
      <meta property="og:title" content={title + titleSuffix} key="og_title" />
      <meta
        property="og:description"
        content={description}
        key="og_description"
      />
      <meta property="og:url" content={url} key="og_url" />
      <meta property="og:locale" content={'fr_FR'} key="og_locale" />
      <meta property="og:image" content={host + image.src} key="og_image" />
      <meta
        property="og:image:width"
        content={image.width}
        key="og_image_width"
      />
      <meta
        property="og:image:height"
        content={image.height}
        key="og_image_height"
      />
      {publication && <PublicationCanonicalUrl publication={publication} />}
      <meta name="twitter:title" content={title} key="twitter_title" />
      <meta
        name="twitter:image:src"
        content={host + image.src}
        key="twitter_image_src"
      />
      <meta
        name="twitter:image:width"
        content={image.width}
        key="twitter_image_width"
      />
      <meta
        name="twitter:image:height"
        content={image.height}
        key="twitter_image_height"
      />
      <script type="application/ld+json">{webpageJsonSchema}</script>
      {(props.isDisconnectedHomepage || props.event || props.resource) && (
        <script type="application/ld+json">{jsonSchema}</script>
      )}
    </Head>
  );
}
