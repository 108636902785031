import { Button, Flex, Icon, VisuallyHidden } from '@chakra-ui/react';
import { PiList, PiMagnifyingGlassThin, PiXThin } from 'react-icons/pi';
import React from 'react';

export default function HeaderTogglableItems({
  children,
  isBurgerMenuOpen,
  onToggleSearchEngineVisibility,
  onToggleBurgerMenuVisibility,
}) {
  return (
    <Flex gap={['0.25rem', '', '1rem']} color="brand.100" alignItems="center">
      {children}
      <Button
        variant="link"
        title="Afficher/masquer le moteur de recherche"
        aria-label="Afficher/masquer le moteur de recherche"
        display={{ base: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="center"
        onClick={onToggleSearchEngineVisibility}
      >
        <Icon as={PiMagnifyingGlassThin} boxSize={8} />
      </Button>
      <Button
        variant="link"
        title="Afficher/Masquer le menu principal"
        display={['flex', null, 'none']}
        onClick={onToggleBurgerMenuVisibility}
        mr="1rem"
      >
        <VisuallyHidden>Afficher/Masquer le menu principal</VisuallyHidden>
        <Icon
          as={PiList}
          boxSize="2em"
          display={{
            base: isBurgerMenuOpen ? 'none' : null,
            md: 'none',
          }}
        />
        <Icon // (close icon)
          as={PiXThin}
          boxSize="2em"
          display={{
            base: isBurgerMenuOpen ? null : 'none',
            md: 'none',
          }}
        />
      </Button>
    </Flex>
  );
}
